<template>
  <div>
    <div class="payments__grid">
      <PaymentButton
        buttonText="Codi"
        :imageSrc="require('@/assets/images/logo/CODI.svg')"
        @click="onClickPaymentMethod('codi')"
        :loading="loading"
        v-if="showCodiButton"
      />

      <PaymentButton
        buttonText="Efectivo"
        :imageSrc="require('@/assets/images/logo/CASH.svg')"
        @click="onClickPaymentMethod('cash')"
        :loading="loading"
        v-if="showCashButton"
      />

      <PaymentButton
        buttonText="Tarjeta"
        :imageSrc="require('@/assets/images/logo/bank_card.png')"
        @click="onClickPaymentMethod('bankcard')"
        :loading="loading"
        v-if="showBankCardButton"
      />

      <PaymentButton
        buttonText="Puntos de recompensa"
        :imageSrc="require('@/assets/images/logo/reward_points.png')"
        @click="onClickPaymentMethod('reward_points')"
        :loading="loading"
        v-if="showRewardPointsButton"
      />

      <PaymentButton
        buttonText="Walleat"
        :imageSrc="require('@/assets/images/logo/walleat.png')"
        @click="onClickPaymentMethod('walleat')"
        :loading="loading"
        v-if="showWalleatButton"
      />

      <PaymentButton
        buttonText="Transferencia/depósito"
        :imageSrc="require('@/assets/images/logo/bank_transfer.png')"
        @click="onClickPaymentMethod('bank_deposit')"
        :loading="loading"
        v-if="showBankDepositButton"
      />
    </div>

    <div v-if="!acceptedPaymentTypes || acceptedPaymentTypes.length === 0">
      <b-alert
        show
        variant="warning"
        class="p-2"
        :title="`No se han configurado métodos de pago`"
      >
        <p>
          Por favor, contacta a la tienda para que configure los métodos de
          pago.
        </p>
      </b-alert>
    </div>
  </div>
</template>

<script>
// Components
import PaymentButton from "@/@core/components/checkout/PaymentButton.vue"

export default {
  components: {
    PaymentButton,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    onClickPaymentMethod: {
      type: Function,
      required: true,
    },
    acceptedPaymentTypes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    showCodiButton() {
      return this.acceptedPaymentTypes?.includes("codi")
    },
    showCashButton() {
      return this.acceptedPaymentTypes?.includes("cash")
    },
    showBankCardButton() {
      return this.acceptedPaymentTypes?.includes("bankcard")
    },
    showRewardPointsButton() {
      return this.acceptedPaymentTypes?.includes("reward_points")
    },
    showBankDepositButton() {
      return this.acceptedPaymentTypes?.includes("bank_deposit")
    },
    showWalleatButton() {
      return this.acceptedPaymentTypes?.includes("walleat")
    },
  },
}
</script>

<styles lang="scss" scoped>
.payments__grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 10px;

  @media (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
</styles>
