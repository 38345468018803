<template>
  <b-modal
    :id="id"
    title="Pago con CoDi"
    no-stacking
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    :disabled.sync="loading"
    @hidden="handleHide"
  >
    <validation-observer ref="accountCelRules" tag="form">
      <b-row>
        <b-col
          class="d-flex flex-column justify-content-center align-items-center"
          cols="12"
        >
          <b-img :src="require('@/assets/images/logo/CODI.svg')" width="150" />
        </b-col>
        <b-col cols="12">
          <div class="mx-2" v-if="!selectedIntent">
            Elige una de las opciones para realizar el pago
            <div class="my-1">
              <b-button
                block
                variant="primary"
                class="uppercase"
                @click="selectedIntent = 'notification'"
              >
                Notificación en tu celular
              </b-button>

              <b-button
                block
                variant="primary"
                class="uppercase"
                @click="selectedIntent = 'qrcode'"
              >
                CÓDIGO QR
              </b-button>
            </div>
          </div>
          <div v-if="selectedIntent === 'notification'" class="mx-2 mt-1">
            <h5 class="mb-0">Número de teléfono</h5>
            <small class="text-muted">
              Agrega tu número de teléfono para confirmar la operación
            </small>

            <b-form-group label-for="v-cel_number">
              <validation-provider
                #default="{ errors, valid }"
                name="Celular"
                rules="required|digits:10"
              >
                <b-form-input
                  id="v-cel_number"
                  v-model="localCelNumber"
                  placeholder="Celular a 10 dígitos"
                  size="lg"
                  type="number"
                  class="text-center"
                  :state="errors.length > 0 ? false : valid ? true : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div v-else-if="selectedIntent === 'qrcode'" class="mx-2 mt-1">
            <h5 class="mb-0">Código QR</h5>
            <small class="text-muted">
              Abre tu aplicación bancaria, dirígete a la sección de CoDi y
              escanea el código QR. También puedes guardar o hacer una captura
              de pantalla y subirla a tu aplicación bancaria dentro de la
              sección de CoDi.
            </small>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <b-col
      cols="12"
      class="d-flex justify-content-center align-items-center my-2"
    >
      <b-button v-b-modal.codi-info variant="link">
        ¿Cómo activar CoDi? <em class="fas fa-info-circle" />
      </b-button>
    </b-col>
    <template #modal-footer>
      <b-button
        :disabled="loading"
        variant="outline-danger"
        @click="handleCancel"
      >
        Cancelar
      </b-button>
      <div>
        <b-button
          :disabled="!selectedIntent || loading"
          :variant="'success'"
          @click="handleSubmit"
        >
          <b-spinner v-if="loading" small />
          Confirmar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"

export default {
  data() {
    return {
      selectedIntent: null,
      localCelNumber: this.celNumber,
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "complete-codi-payment",
    },
    celNumber: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    handleCheckout: {
      type: Function,
      required: true,
    },
    onCancelCheckout: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  methods: {
    handleHide() {
      this.selectedIntent = null
    },
    handleCancel() {
      this.selectedIntent = null
      this.localCelNumber = this.celNumber

      this.$bvModal.hide(this.id)
      this.onCancelCheckout()
    },
    async handleSubmit() {
      await this.handleCheckout(this.selectedIntent)
    },
  },
  watch: {
    localCelNumber(val) {
      if (val !== this.localCelNumber) {
        this.$emit("onChangeCelNumber", val)
      }
    },
  },
}
</script>
