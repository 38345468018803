<template>
  <b-modal
    :id="id"
    title="Metodos de pago"
    hide-footer
    no-stacking
  > 
    <PaymentMethods
      :loading="loading"
      :acceptedPaymentTypes="acceptedPaymentTypes"
      :onClickPaymentMethod="onClickPaymentMethod"
    />
  </b-modal>
</template>

<script>
import PaymentMethods from "./payment-methods.vue"

export default {
  components: {
    PaymentMethods,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "payment-methods-modal",
    },
    onClickPaymentMethod: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    acceptedPaymentTypes: {
      type: Array,
      required: true,
    },
  },
}
</script>
